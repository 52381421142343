import React, { useEffect, useMemo, useState } from "react";
import _get from "lodash/get";

import { Slider } from "@onlinesales-ai/slider-v2";
import { Text } from "@onlinesales-ai/label-v2";
import { FormWrapper } from "@onlinesales-ai/form-components-v2";
import { EditInput } from "@onlinesales-ai/input-v2";
import { formattedNumber } from "@onlinesales-ai/util-methods-v2";

const SliderInput = ({
  title,
  titleGuidText,
  dataKey,
  min,
  max,
  sliderStepGap,
  showInputField,
  formValues,
  formErrors,
  isEditable,
  onChange,
  validations,
  onError,
  labelDefinationKey,
  showMinMaxSubText,
  inputValidator,
  ...props
}) => {
  const value = useMemo(() => {
    return _get(formValues, dataKey);
  }, [formValues, dataKey]);

  const [sliderValue, setSliderValue] = useState(value);

  const onValueChange = (val) => {
    setSliderValue(val);
  };

  useEffect(() => {
    onChange({ [dataKey]: sliderValue });
  }, [sliderValue]);

  useEffect(() => {
    if (!value && min) {
      onChange({ [dataKey]: min });
    }
  }, [value, min]);

  return (
    <FormWrapper
      title={title}
      titleGuidText={titleGuidText}
      dataKey={dataKey}
      formValues={formValues}
      formErrors={formErrors}
      error={formErrors[dataKey]}
      hasError={props.showErrors && !!formErrors[dataKey]}
      labelColumns={12}
      labelDefinationKey={labelDefinationKey}
    >
      {showInputField && (
        <EditInput
          type="number"
          value={value || 0}
          onChange={onValueChange}
          onType={onValueChange}
          enableEdit={isEditable}
          maxLength={String(max).length}
          min={min}
          max={max}
          validate={inputValidator}
          doNotShowCharLimit
          formatNumber
          showCurrency
          showTooltip
          editIconClass="pad-r-2"
          setParseValue
        />
      )}
      <Slider
        tooltip="auto"
        size="sm"
        step={sliderStepGap}
        min={min}
        max={max}
        value={sliderValue || 0}
        disabled={!isEditable}
        onChange={(ev) => setSliderValue(ev.target.value)}
      />
      {((min !== undefined && max !== undefined) && showMinMaxSubText) && (
        <div className="d-align-between">
          <Text type="tertiary" size="small" className="d-align-center" italic>
            Min: {formattedNumber(min, { showCurrency: true })}
          </Text>
          <Text type="tertiary" size="small" className="d-align-center" italic>
            Max: {formattedNumber(max, { showCurrency: true })}
          </Text>
        </div>
      )}
    </FormWrapper>
  );
};

SliderInput.defaultProps = {
  showInputField: true,
  min: 0,
  showMinMaxSubText: true,
  sliderStepGap: 1,
};

export default SliderInput;
