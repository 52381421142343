import React, { useState, useRef, useMemo, useCallback, useEffect } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import { Trans, useTranslation } from "react-i18next";
import classnames from "classnames";

import {
  getCurrencyDOM,
  marketplaceNameReplacer,
  customMergeOS,
} from "@onlinesales-ai/util-methods-v2";
import { HelpLink } from "@onlinesales-ai/helplink-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";

import "./Text.less";

const CLASS_NAME_MAPPING = {
  sizes: {
    smallest: "text-smallest",
    smaller: "text-smaller",
    extraSmall: "text-extra-small",
    small: "text-small",
    medium: "text-medium",
    large: "text-large",
    extraLarge: "text-extra-large",
    xxLarge: "text-xx-large",
    jumbo: "jumbo-icon",
  },
  types: {
    primary: "primary-text",
    secondary: "secondary-text",
    tertiary: "gray-text",
    tertiaryLight: "light-gray-text",
    success: "success-text",
    successLight: "success-light-text",
    successLight2: "success-text-2",
    error: "error-text",
    warning: "warning-text",
    warningLighter: "warning-text-3",
    white: "white-text",
    successLight: "success-light",
    link: "anchor-link",
    warningLight: "warning-light",
    blue: "blue-text",
  },
  weights: {
    light: "fw-light",
    regular: "fw-normal",
    semiBold: "fw-semibold",
    bold: "fw-bold",
  },
  lineHeights: {
    normal: "lh-normal",
    medium: "lh-medium",
  },
};

const Text = ({
  labelDefinationKey,
  icon,
  iconClassName,
  tooltipPosition: pTooltipPlacement,
  customTransComponents,
  children,
  showEllipsis,
  centerEllipsis,
  ellipsisLines,
  size,
  type,
  italic,
  weight,
  block,
  lineHeight,
  tagProps,
  className,
  tagName: TagName = "label",
  translationConfig,
  fallBackLabel,
  suffix,
  uppercase,
  alwaysShowTooltip,
  innerTextClassName,
  useTransForTranslation,
  ...customOverrides
}) => {
  const { labelDefination = {} } = useSelector((state) => state.DomainConfig || {});
  const [timeStamp] = useState(new Date().getTime());
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const wrapperRef = useRef();
  const tooltipIconRef = useRef(null);
  const [isOverflow, setIsOverflow] = useState(false);
  const [currentScrollHeight, setCurrentScrollHeight] = useState(0);
  const [currentClientHeight, setCurrentClientHeight] = useState(0);
  const [currentScrollWidth, setCurrentScrollWidth] = useState(0);

  const { t } = useTranslation();

  const {
    popoverConfig,
    overlayConfig,
    containerClass,
    underline,
    text,
    tooltipText,
    showInfoIcon,
    tooltipPlacement,
    hasDefination,
    tooltipClass,
    helpLinkKey: textHelpLinkKey,
  } = useMemo(() => {
    let finalResult = {};
    if (labelDefination && labelDefinationKey) {
      const defination = labelDefination[labelDefinationKey] || {};
      finalResult = customMergeOS({}, defination, customOverrides);
    } else if (customOverrides) {
      finalResult = customOverrides;
    }
    return {
      ...finalResult,
      hasDefination:
        !_isEmpty(finalResult) &&
        (finalResult?.text || (finalResult?.showInfoIcon && !_isEmpty(finalResult?.popoverConfig))),
    };
  }, [customOverrides, labelDefinationKey, labelDefination]);

  const calclulateEllipsis = () => {
    const { scrollWidth, clientWidth, scrollHeight, clientHeight } = wrapperRef.current || {};
    const isOverflowingVertically = ellipsisLines && scrollHeight > clientHeight;
    const isOverflowingHorizontally = scrollWidth > clientWidth || (scrollWidth === clientWidth && isOverflow);

    if (isOverflowingVertically || isOverflowingHorizontally) {
      setIsOverflow(true);

      if (isOverflowingHorizontally) {
        setCurrentScrollWidth(scrollWidth);
      }

      if (currentScrollHeight === 0) {
        setCurrentScrollHeight(scrollHeight);
        setCurrentClientHeight(clientHeight);
      }
    } else if (
      currentScrollHeight &&
      currentClientHeight &&
      clientHeight < currentScrollHeight &&
      currentClientHeight <= scrollHeight
    ) {
      setIsOverflow(true);
    } else {
      setIsOverflow(false);
      setCurrentScrollHeight(0);
      setCurrentClientHeight(0);
      setCurrentScrollWidth(clientWidth);
    }
  };

  useEffect(() => {
    calclulateEllipsis();
  }, [text, children]);

  useEffect(() => {
    if (showEllipsis) {
      window.addEventListener("resize", calclulateEllipsis);
      calclulateEllipsis();
    }
    return () => {
      if (showEllipsis) {
        window.removeEventListener("resize", calclulateEllipsis);
      }
    };
  }, [showEllipsis]);

  const {
    popoverContent = "",
    popoverClass = "",
    placement = "auto",
    helpLinkKey,
    enableBoxShadow,
    popoverContainer,
    popoverProps = {},
    showBulletsForArrayValue = true,
  } = useMemo(() => {
    return popoverConfig || {};
  }, [popoverConfig]);

  const TranslationComponents = {
    currency: getCurrencyDOM(),
    bold: <b />,
    ...customTransComponents,
  };

  const getTransConfig = useCallback(
    (textToRender) => {
      return {
        defaults: textToRender,
        values: {
          ...(translationConfig?.values || {}),
          marketplaceName: marketplaceNameReplacer("__MARKETPLACE_NAME__"),
        },
        components: {
          ...TranslationComponents,
          ...(translationConfig?.components || {}),
        },
      };
    },
    [translationConfig, text],
  );

  const wrapperClassName = useMemo(() => {
    let classes = `${containerClass} ${className}`;
    if (underline && !showInfoIcon) {
      classes += " add-underline";
    }
    if (showEllipsis) {
      classes += " w100p";
    }
    if (italic) {
      classes += " italic";
    }
    if (block) {
      classes += " d-block";
    }
    if (uppercase) {
      classes += " uppercase";
    }
    if (type && CLASS_NAME_MAPPING.types[type]) {
      classes += ` ${CLASS_NAME_MAPPING.types[type]}`;
    }
    if (size && CLASS_NAME_MAPPING.sizes[size]) {
      classes += ` ${CLASS_NAME_MAPPING.sizes[size]}`;
    }
    if (weight && CLASS_NAME_MAPPING.weights[weight]) {
      classes += ` ${CLASS_NAME_MAPPING.weights[weight]}`;
    }
    if (lineHeight && CLASS_NAME_MAPPING.lineHeights[lineHeight]) {
      classes += ` ${CLASS_NAME_MAPPING.lineHeights[lineHeight]}`;
    }
    return classes;
  }, [underline, showInfoIcon, showEllipsis, className, type, size, weight, italic, block]);

  const textDom = (val) => {
    if (typeof val === "string") {
      return (
        <span className={classnames("inner-label", innerTextClassName)}>
          {useTransForTranslation ? <Trans {...getTransConfig(val)} /> : t(val)}
        </span>
      );
    } else {
      return val;
    }
  };

  const centerEllipsisDom = (txt) => {
    return (
      <span className="center-ellipsis d-flex">
        <span className="left-content ellipsis-text">{textDom(txt)}</span>
        <span className="ellipsis-separator">...</span>
        <span className="right-content ellipsis-text">{textDom(txt)}</span>
      </span>
    );
  };

  const renderTextDom = (txt) => {
    return (
      <>
        {(showEllipsis && isOverflow) || alwaysShowTooltip ? (
          <WithTooltip title={tooltipText || txt} placement={pTooltipPlacement || tooltipPlacement} tooltipClass={tooltipClass}>
            {centerEllipsis ? (
              <span>{centerEllipsisDom(txt)}</span>
            ) : (
              <span
                className={`d-block ${
                  ellipsisLines ? `ellipsis-line ${ellipsisLines}` : "ellipsis-text"
                } ${ellipsisLines && isOverflow && "ellipsis-indicator"}`}
              >
                {textDom(txt)}
              </span>
            )}
          </WithTooltip>
        ) : (
          <>{textDom(txt)}</>
        )}
      </>
    );
  };

  return (
    <>
      {(hasDefination || children) ? (
        <>
          <TagName
            ref={wrapperRef}
            className={`os-tooltip-text ${wrapperClassName}`}
            {...(tagProps || {})}
          >
            {icon && <span className={`icon ${icon} ${iconClassName}`} />}
            {(text || children) && (
              <>{text ? <>{renderTextDom(text)}</> : <>{renderTextDom(children)}</>}</>
            )}
            {suffix}
            {textHelpLinkKey && (
              <HelpLink className="ml-1" helpLinkKey={textHelpLinkKey} />
            )}
            {showInfoIcon && (
              <em className="text-info-icon-wrapper">
                <span
                  ref={tooltipIconRef}
                  className="icon icon-info-circle1 text-info-icon"
                  onMouseOver={(e) => {
                    e.stopPropagation();
                    setShow(true);
                  }}
                  onFocus={(e) => {
                    e.stopPropagation();
                    setShow(true);
                  }}
                  onMouseOut={() => setShow(false)}
                  onBlur={() => setShow(false)}
                />
              </em>
            )}
          </TagName>
          {!_isEmpty(popoverConfig) && (
            <Overlay
              show={show}
              target={tooltipIconRef.current}
              placement={placement}
              container={popoverContainer}
              containerPadding={20}
              transition
              {...overlayConfig}
            >
              <Popover
                className={`os-text-tooltip-popover ${popoverClass} ${
                  helpLinkKey ? "with-help-link" : ""
                } ${enableBoxShadow ? "box-shadow-only" : ""}`}
                id={`popover-${timeStamp}`}
                delay={{ show: 250, hide: 400 }}
                onMouseOver={() => setShow(true)}
                onMouseOut={() => setShow(false)}
                onClick={(e) => e.stopPropagation()}
                {...popoverProps}
              >
                <span className="text-popover">
                  <span className="popover-content-inner">
                    {showBulletsForArrayValue && Array.isArray(popoverContent) ? (
                      <ul>
                        {popoverContent.map((item) => {
                          return <li>{textDom(item)}</li>;
                        })}
                      </ul>
                    ) : (
                      <>
                        {typeof popoverContent === "string" ? (
                          <>{textDom(popoverContent)}</>
                        ) : (
                          popoverContent
                        )}
                      </>
                    )}
                  </span>
                  {helpLinkKey && (
                    <HelpLink helpLinkKey={helpLinkKey} setPopoverFalse={() => setShow(false)} />
                  )}
                </span>
              </Popover>
            </Overlay>
          )}
        </>
      ) : null}
      {!hasDefination && fallBackLabel}
    </>
  );
};

Text.defaultProps = {
  customTransComponents: {},
  enableBoxShadow: false,
  showEllipsis: false,
  centerEllipsis: false,
  suffix: null,
  useTransForTranslation: true, // added this support to handle the rendering of "<" sign
};

export default Text;
